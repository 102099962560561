@import '../../vars';

.input {
  font-family: $fontFamilySourceSansPro;
  font-size: $fontSize16;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 12px 0px 12px;
  margin-top: $margin8;

  &:focus {
    outline: none;
  }
}

.light {
  background: $input-light-bg-color;
  color: $input-light-font-color;
  border: 1px solid $input-border-color;
}

.dark {
  background: $input-dark-bg-color;
  color: $input-dark-font-color;
}

.large {
  height: 48px;
}

.medium {
  height: 36px;
}

.small {
  height: 24px;
}

.label {
  display: block;
}

.sublabel {
  margin-top: $margin0 !important;
}

.required {
  color: $input-required-color;
  margin-left: $margin4;
}

.error {
  margin-top: $margin4;
}
.input:disabled {
  background-color: $input-border-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.whiteLineInput {
  border-bottom: 2px solid rgba(255, 253, 253, 0.703);
  background-color: transparent;
  width: 100%;
}