@import '../../vars';

.dashboard {
  display: flex;
  gap: 80px;
  padding: 24px;
  background-color: $dashboard-bg-color;
}

.dashboardCol{
  display: flex;
  flex-direction: column;
}

.dashboardRow {
  display: flex;
  gap: 40px;
}

.dashboardItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.border{
  padding: 12px;
  border: 1px solid $divider-color;
}