@import '../../vars';

.passwordRequirements {
  padding-left: 24px;
  margin-top: $margin0;
  li {
    padding-left: 0;
    margin-top: $margin8;

    .checkIcon,
    .closeIcon {
      height: 16px;
      width: 16px;
      margin-left: $margin4;
      align-self: center;
    }

    .checkIcon {
      color: $checkIcon;
    }

    .closeIcon {
      color: $closeIcon;
    }
  }
}
