@import '../../vars';

.deleteButton {
  margin-left: $margin4;
}

.upload {
  align-items: center !important;
  width: 100%;
  margin-top: 8px;
  .uploadIcon {
    color: $font-color-documents;
    margin-right: 8px;
  }
  .uploadProgressCol {
    width: 25%;
    margin-top: auto;
    .uploadProgress {
      color: $loading-inner-color-secondary;
      margin-left: -6px;
    }
  }

  .linearProgressCol {
    width: 80%;
    .linearProgress {
      height: 6px;
      background-color: $progress-bar;
    }
    .fileName {
      margin-top: 4px;
      color: $loading-inner-color-secondary;
    }
  }
  .iconButtons {
    width: 20%;
    margin-top: -30px;
    flex-direction: row !important;
  }
  .buttons {
    color: $font-color-documents !important;
    font-size: 20px !important;
  }
}
@media screen and (max-width: $mobile) {
  .upload {
    margin-left: -25px;
  }
}
