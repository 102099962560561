@import '../../vars';

.referral {
  background: $signin-bg-color;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  .form{
    height: 100%;
    width: 100%;
  }
}
