@import '../../vars';

.customMenuItem{
    font-weight: $fontWeight400 !important;
    font-family: $fontFamilySourceSansPro !important
}
.customMenuItemBorder{
    border-bottom: 0.5px solid $menuButton-border-color !important;
}

