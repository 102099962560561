@import '../../vars';

.form {
  p:not(:first-of-type) {
    margin-top: $margin12;
  }
}

.agentDealFooter {
  width: 47.9%;
  justify-content: space-evenly !important;
  margin-top: 20px;
}

.overviewCards {
  max-width: 100%;
  flex-wrap: wrap;
}
.overviewCard {
  border: 1px solid $divider-color;
  background: #f2f2f2;
  padding: 16px 24px;
  width: 224px;
  height: 88px;
}

.additionalInfoSection {
  width: 100%;
  border: 1px solid $divider-color;
  padding: 24px;
}

.additionalInfoSection:not(:last-child) {
  border-bottom: none;
}

.notes {
  max-width: 364px;
}

/*********************Deals Table CSS**************************/
.dealsTableMain {
  .agentColumn {
    display: flex;
    align-items: center;
    gap: 12px;
    .agentName {
      color: $table-cell-color;
      font-weight: $fontWeight600;
    }
  }
  .dealType {
    color: $table-cell-color;
    font-weight: $fontWeight600;
  }
  .statusColumn {
    padding: 8px 16px;
    width: 120px;
  }
}

/************************DealFormHeader CSS : START ************************/
.dealFormHeaderMain {
  .mandatoryFieldText {
    margin-bottom: $margin10;
    color: $loading-inner-color-secondary;
    .requiredIcon {
      color: $requiredIcon;
    }
  }
  .primaryAgentLabel p {
    color: #7c7c7c;
  }
  .agentNameLable {
    display: flex;
    justify-content: space-between;
  }
  .addAgentDropdown {
    margin-top: $margin35;
  }
}

// Css for comment section alongside
.leftSide {
  flex-basis: 90%;
}
.flexContainer {
  display: flex;
  width: 100%;
}
//for 125% view
.rightSide {
  flex-basis: 10%;
  position: absolute;
  left: 80%;
  margin-left: -145px;
  margin-top: 38px;
  bottom: 0;
}
//for 125% edit
.rightSideEdit {
  flex-basis: 10%;
  position: absolute;
  left: 59%;
  bottom: 0;
  transform: scale(0.9);
}

.mobileDealComment {
  display: none;
}

.dealFooter {
  margin-top: 10px;
}

// CSS for 150% zoom level
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  //for view
  .rightSide {
    flex-basis: 10%;
    position: absolute;
    left: 60%;
    bottom: 0.5%;
    margin: 0 auto;
    transform: scale(0.8);
  }
  //for edit
  .rightSideEdit {
    flex-basis: 10%;
    position: fixed;
    left: 60%;
    top: 125px;
    transform: scale(0.77);
  }
}
/************************DealFormHeader CSS : END ************************/
.dealsStepperBtn {
  width: $width141;
}

.responsive {
  width: 47.9%;
}
/***************** Step 1 CSS START ***************/
.dealsStepOne {
  .dealTypeTitle {
    margin-top: $margin20;
  }
}
/***************** Step 1 CSS END ***************/

/************************Step 3(Documents) CSS : START ************************/
.documentMain {
  width: 47.9%;
  .orDivider {
    font-family: $fontFamilySourceSansPro;
    color: $loading-inner-color-secondary;
    margin: 20px 0px 5px 68px;
  }
  .documentsRequiredInfo {
    font-size: $fontSize14;
    color: $loading-inner-color-secondary;
    margin-top: $margin12;
    font-family: $fontFamilySourceSansPro;
  }
  .headerLabel {
    margin-top: $margin22;
    font-family: $fontFamilySourceSansPro;
  }
}
/************************Step 3(Documents) CSS : END ************************/

/***************** Step 5 CSS START ***************/
.annual_personal_deal_checkbox {
  width: 100%;
  display: flex;
  .label {
    width: 100%;
    margin-top: 10px;
  }
  input[type='checkbox'] {
    margin-left: 60px;
    accent-color: $select-primary-color;
    height: 20px !important;
    width: 20px !important;
  }
}
.referral_amount {
  padding-top: 40px;
}
/***************** Step 5 CSS END ***************/

/**************Media Query: START***********************/
@media screen and (max-width: $mobile) {
  .mobileDealComment {
    display: flex;
    margin-top: 20px;
  }
  .rightSideEdit {
    display: none;
  }
  .rightSide {
    display: none;
  }
  .agentDealFooter {
    width: 100%;
    gap: 20px;
  }
  .dealHeader,
  .dealTitle {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .dealsTableMain {
    .dealsDivMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      margin-top: -20px;
      flex-direction: column;
      .dealsInnerDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        margin-bottom: $margin20;
      }
    }
  }
  .dealFormHeaderMain {
    display: flex;
    flex-direction: column;
    .addAgentDropdown {
      margin-top: 0px;
    }
  }
  .responsive {
    width: 100%;
  }
  .documentMain {
    width: 100% !important;
  }
  .annual_personal_deal_checkbox {
    input[type='checkbox'] {
      margin-left: 5px;
    }
  }
  .referral_amount {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .flexContainer {
    display: block;
  }
  .dealFooter {
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
    .dealFooterButton {
      width: 140px;
    }
  }
}
/**************Media Query:END ***********************/
