@import '../../vars';

.label {
  display: block;
}

.sublabel {
  margin-top: 0px !important;
}

.required {
  color: $input-required-color;
  margin-left: $margin4;
}

.error {
  margin-top: $margin4;
}

.disabled {
  background-color: #d9d9d9;
}

.datePickerOuterDiv {
  margin-top: $margin8;
  width: $width100;
}
