@import '../../vars';

@mixin commentLine($top, $height) {
  .avatar::before {
    top: $top;
    left: 20px;
    position: absolute;
    content: ' ';
    width: 0.5px;
    height: $height;
  }
}

.commentsContainer {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.createComment {
  @include commentLine(80px, 40px);
}

.commentLine {
  @include commentLine(40px, 100%);
}

.message {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  font-size: 18px;
  font-family: Roboto;
  color: $commnetHeaderColor;
  max-width: 380px;
  overflow-wrap: break-word;
}

.commentHeaderDivider {
  border-top: 1px solid $commentDividerColor;
  margin: $margin0;
}

.commentCardHeader {
  gap: 10px;

  .commentTitle {
    color: $commnetHeaderColor;
    margin-left: $margin12;
  }

  .commentIcon {
    margin-left: 44px;
    width: 23px;
  }
}

.nameDateDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .senderName {
    color: $commnetHeaderColor;
    font-weight: bold;
    margin-right: 8px;
    font-size: 18px;
  }

  .date {
    color: $commentDateColor;
    line-height: 22px;
    font-size: 18px;
  }
}

.cardInputSection {
  padding: 0px !important;
}

@media screen and (max-width: $mobile) {
  .commentsContainer {
    width: 100%;
  }
}

.commentsContainer {
  overflow-y: auto;
  height: 220px;
}

.commentWrapper {
  padding: 8px;
  border-radius: 10px;
  margin: 8px;
  display: flex;
  flex-direction: column;
}

.commentBubbleRight {
  align-self: flex-end;
  width: 90%;
  background-color: #aaefef80;
  color: $commnetHeaderColor;
}

.commentBubbleLeft {
  width: 90%;
  color: $commnetHeaderColor;
  background-color: #8ff4ad6c;
}

.commentContent {
  display: flex;
  flex-direction: column;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-size: $fontSize13;
  font-family: Roboto;
  color: $commnetHeaderColor;
}

.commentHeader {
  align-items: baseline;
}
