@import '../../../vars';

.users {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}

.button {
  margin-top: $margin16;
}

.modal {
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: rgba(0, 0, 0, 0.4);

  .body {
    background: #ffffff;
    max-height: 70vh;
    width: 50%;
    height: min-content;
    max-height: 90%;
    position: relative;

    .content {
      overflow-y: auto;
      max-height: 70vh;
      height: 100%;
      padding: 16px 16px;
    }

    .closeIcon {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .modal {
    .body {
      max-height: 90vh;
      width: 90%;
      .content {
        max-height: 90vh;
      }
    }
  }
}
