@import '../../vars';

.label {
  display: block;
  margin-bottom: $margin8;
}

.required {
  color: $input-required-color;
  margin-left: $margin4;
}

.error {
  margin-top: $margin4;
}
