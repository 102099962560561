@import '../../vars';
  
  /* CSS for 125% zoom level */
@media screen and (min-resolution: 120dpi) and (max-resolution: 130dpi) {
    .customDatepicker .react-date-picker__inputGroup input {
        font-size: $fontSize13; 
      }
      .customDatepickerDiv{
        margin-top: $margin6 !important;
      }
      .customInput{
        font-size: $fontSize13;
        margin-top: $margin6 !important;
      }
      .customSelectPlaceholder__placeholder{
        font-size: $fontSize13;    
      }
      .customSelectPlaceholder__single-value{
        font-size: $fontSize13;
      }
}
  /* CSS for 150% zoom level */
  @media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
    .customDatepicker .react-date-picker__inputGroup input{
        font-size: $fontSize11; 
      }
      .customDatepickerDiv{
        margin-top: $margin4 !important;
      }
      .customInput{
        font-size: $fontSize11;
        margin-top: $margin4 !important;
      }
      .customSelectPlaceholder__input{
        font-size: $fontSize11;
      }
      .customSelectPlaceholder__placeholder{
        font-size:$fontSize11;    
      }
      .customSelectPlaceholder__single-value{
        font-size: $fontSize11;
      }
  }
  /* CSS rules for 175% zoom level */
@media screen and (min-resolution: 168dpi) and (max-resolution: 186dpi) {
    .customDatepicker .react-date-picker__inputGroup {
        font-size: $fontSize9; 
      }
      .customDatepickerDiv{
        margin-top: $margin2 !important;
      }
      .customInput{
        font-size: $fontSize9;
        margin-top: $margin2 !important;
      }
      .customSelectPlaceholder__placeholder{
        font-size:$fontSize9;    
    }
    .customSelectPlaceholder__single-value{
        font-size: $fontSize9;
      }
}