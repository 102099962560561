@import '../../vars.scss';

.form {
  label:not(:first-of-type) {
    margin-top: $margin12;
  }
}

.additionalInfoSection {
  width: 100%;
  border: 1px solid $divider-color;
  padding: 24px;
}

.additionalInfoSection:not(:last-child) {
  border-bottom: none;
}

.notes {
  max-width: 364px;
}

@media screen and (max-width: $mobile) {
  .activitylogHeader,
  .activitylogTitle {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .activitylogsTableMain {
    .activitylogDivMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      margin-top: -20px;
      flex-direction: column;
      .activitylogInnerDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        margin-bottom: $margin20;
      }
    }
  }
}
/*********************activitylog Table CSS******************************/
.activitylogsTableMain {
  .agentColumn {
    display: flex;
    align-items: center;
    gap: 12px;
    .agentName {
      color: $table-cell-color;
      font-weight: $fontWeight600;
    }
  }
  .typeColumn {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .activitylogType {
      color: $table-cell-color;
      font-weight: $fontWeight600;
    }
  }
  .statusColumn {
    padding: 8px 16px;
    width: 120px;
  }
}

/************************activitylogFormHeader CSS : START ************************/
.activitylogFormHeaderMain {
  .requiredIcon {
    color: $requiredIcon;
  }
}

.documentMain {
  width: 47.9%;

  .orDivider {
    font-family: $fontFamilySourceSansPro;
    color: $loading-inner-color-secondary;
    margin: 20px 0px 5px 68px;
  }

  .documentsRequiredInfo {
    font-size: $fontSize14;
    color: $loading-inner-color-secondary;
    margin-top: $margin12;
    font-family: $fontFamilySourceSansPro;
  }

  .headerLabel {
    margin-top: $margin22;
    font-family: $fontFamilySourceSansPro;
  }
}

.dealFormHeaderMain {
  display: flex;
  flex-direction: column;

  .agentNameLable {
    flex-direction: column;
  }
}

.mandatoryFieldText {
  margin-bottom: $margin10;
  color: $loading-inner-color-secondary;

  .requiredIcon {
    color: $requiredIcon;
  }
}
//CSS for 125% zoom level in view
.rightSide {
  flex-basis: 10%;
  margin: 0 auto;
  max-width: 25%;
  bottom: 0;
  top: 140px;
  position: absolute;
  right: 55px;
}
.rightSideEdit {
  flex-basis: 10%;
  position: absolute;
  left: 61%;
  bottom: 0;
  transform: scale(0.94);
}

// CSS for 150% zoom level
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  //for view
  .rightSide {
    flex-basis: 10%;
    position: absolute;
    bottom: 58%;
    margin: 0 auto;
    transform: scale(0.9);
    max-width: 30%;
  }
  //for edit
  .rightSideEdit {
    flex-basis: 10%;
    position: fixed;
    left: 68%;
    top: 190px;
    transform: scale(0.77);
  }
}
