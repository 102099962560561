@import '../../../vars';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  background: $nav-bg-color;
  border-left: 1px solid $nav-divider-color;
  flex-basis: 40px;

  p {
    color: $nav-font-color;
  }

  .icon {
    margin-right: $margin8;
    height: 16px !important;
    width: 16px !important;
    color: $nav-font-color;
  }
}

@media screen and (max-width: $mobile) {
  .header {
    border-top: 1px solid $font-color-white;
    border-left: none;
  }
}
