@import '../../vars';

.customTabsMain {
  .tabHeaderRoot {
    .customTabHead {
      color: #a3a3a3;
      font-size: $fontSize22;
      text-transform: none;
      &:hover,
      &:active {
        background-color: $font-color-primary !important;
        color: white !important;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
@media screen and (max-width: $mobile) {
.tabsContainer {
  border: 1px solid $border-color;
  padding: 8px;
}
.tabHeaderRoot {
  .customTabHead {    
    &:hover {
      opacity: 1 !important;
    }
  }
}
}
