@import '../../vars';

.headerMain {
  margin-bottom: $margin50;
  align-items: center !important;
  justify-content: space-between !important;
}

.userName {
  margin-top: $margin6 !important;
}

.avtar {
  margin: $margin0 $margin20;
}

@media screen and (max-width: $mobile) {
  .userName {
    display: none;
  }
}

/***********Different System Resolution CSS **********************************/
/* CSS for 125% zoom level */
@media screen and (min-resolution: 120dpi) and (max-resolution: 130dpi) {
  .headerMain {
    margin-bottom: $margin35;
    .badgeIcon {
      margin-top: $margin4;
    }
    .badgeIcon span:nth-child(2) {
      padding: $padding0 $padding0;
      font-size: $fontSize9;
      height: $width16;
      width: $width16;
      min-width: 0px;
    }
    .notificationIcon {
      font-size: $fontSize28 !important;
    }
    .profileAvatar {
      width: $width35 !important;
      height: $width35 !important;
    }
  }
  .headerText {
    font-size: $fontSize30;
  }
  .preconHeaderSubText {
    font-size: $fontSize20 !important;
    margin-top: $margin0 !important;
  }
  .userName {
    font-size: $fontSize18;
    margin-top: $margin5 !important;
  }
}

/* CSS for 150% zoom level */
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  .headerMain {
    margin-bottom: $margin26;
    .badgeIcon {
      margin-top: $margin7;
    }
    .badgeIcon span:nth-child(2) {
      padding: $padding0 $padding0;
      font-size: $fontSize9;
      height: $width14;
      width: $width14;
      min-width: 0px;
    }
    .notificationIcon {
      font-size: $fontSize24 !important;
    }
    .profileAvatar {
      width: $width30 !important;
      height: $width30 !important;
    }
  }
  .headerText {
    font-size: $fontSize25;
  }
  .preconHeaderSubText {
    font-size: $fontSize16 !important;
    margin-top: -8px !important;
  }
  .userName {
    font-size: $fontSize15;
    margin-top: $margin0 !important;
  }
}

/* CSS rules for 175% zoom level */
@media screen and (min-resolution: 168dpi) and (max-resolution: 186dpi) {
  .headerMain {
    margin-bottom: $margin22;
    .badgeIcon {
      margin-top: $margin10;
    }
    .badgeIcon span:nth-child(2) {
      padding: $padding0 $padding0;
      font-size: $fontSize7;
      height: $width11;
      width: $width11;
      min-width: 0px;
    }
    .notificationIcon {
      font-size: $fontSize20 !important;
    }
    .profileAvatar {
      width: $width26 !important;
      height: $width26 !important;
      margin-top: $margin8;
    }
  }
  .headerText {
    font-size: $fontSize21;
  }
  .preconHeaderSubText {
    font-size: $fontSize14 !important;
    margin-top: -11px !important;
  }
  .userName {
    font-size: $fontSize13;
  }
}
.announcementWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.announcementCount {
  position: absolute;
  top: 3px;
  right: -134px;
  background-color: $closeIcon;
  color: $cardBgColor;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: $fontSize12 !important;
  line-height: 20px !important;
  text-align: center;
}
