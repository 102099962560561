@import '../../vars';

.radio {
  accent-color: $radio-color;
  padding: 0px;
  margin: $margin0;
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
}

.radio_label {
  font-family: $fontFamilySourceSansPro;
  font-style: normal;
  font-weight: $fontWeight400;
  font-size: $fontSize16;
  line-height: 20px;
  padding-left: 8px;

  &:hover {
    cursor: pointer;
  }
}

.error {
  margin-top: $margin4;
}

.required {
  color: $input-required-color;
  margin-left: $margin4;
}

.groupLabel {
  margin-bottom: $margin8;
}
