@import '../../vars';

.collapsibleHeader {
    cursor: pointer;
    user-select: none;
    margin-bottom: $margin24;
}

.chevron {
    margin-left: $margin16;
    transition: transform 150ms ease;
}

.flipped {      
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.collapsibleContent {
    // -moz-transition: height 150ms ease;
    // -ms-transition: height 150ms ease;
    // -o-transition: height 150ms ease;
    // -webkit-transition: height 150ms ease; 
    // transition: height 150ms ease;
    overflow: hidden;
    height: 0px;
    margin-bottom: $margin24;
    width: 100%;
}

.visible {
    height: auto;
}