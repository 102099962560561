@import '../../vars';

.customMenuItem{
    text-transform: uppercase;
    font-weight: $fontWeight600 !important;
    font-family: $fontFamilySourceSansPro !important
}
.customMenuItemBorder{
    border-bottom: 0.5px solid $menuButton-border-color !important;
}
.customActionMenuItem{
    font-family: $fontFamilySourceSansPro!important;
    font-size: $fontSize10;
    line-height: 13px;
}