@import '../../vars';

Input {
  border: none;
  border-bottom: solid #e4e2e2 1px;
}

.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../../src/assets/Login_page_image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
}

.centerContent {
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.logoContainer {
  position: absolute;
  top: 20%;
  left: 2%;
  transform: translateY(-50%);
}
.passwordResetButton {
  margin-top: 30px !important;
  margin-left: 120px !important;
  background-color: transparent !important;
  border: 2px solid white !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  width: 40% !important;
}
.passwordRequirements {
  padding-left: 24px;
  margin-top: $margin0;
  color: white !important;
  li {
    padding-left: 0;
    margin-top: $margin8;
    .checkIcon,
    .closeIcon {
      height: 18px;
      width: 18px;
      margin-left: $margin4;
      align-self: center;
    }
    .checkIcon {
      color: $checkIcon;
      font-size: 20;
    }
    .closeIcon {
      color: $closeIcon;
      font-size: 20;
    }
  }
}

@media screen and (max-width: $mobile) {
  .logoContainer {
    top: 10%;
    transform: translateY(-25%);
  }
  .subheading {
    font-size: 12px;
  }
  .centerContent {
    width: 80%;
  }
  .passwordResetButton {
    margin-top: 7% !important;
    margin-left: 30% !important;
  }
}
