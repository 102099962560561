@import '../../../vars';

.message,
.isMeMessage {
  margin: 10px 0;
  padding: 6px 18px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.isMeMessage {
  align-self: flex-end;
  align-items: flex-end;
}

.text {
  padding: 4px 8px;
  border-radius: 6px;
  background: $chat-bubble;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.image {
  font-weight: $fontWeight400;
  font-size: $fontSize14;
  line-height: 20px;
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
  cursor: pointer;
}

.file {
  display: flex;
  align-items: center;
  svg {
    height: 25px;
    width: 25px;
    color: $black;
  }
  &:hover {
    svg,
    p {
      color: $alert-info-icon-color;
    }
  }
}
