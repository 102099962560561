@import '../../vars';

.checkbox {
  accent-color: $radio-color;
  cursor: pointer;
}

.label {
  cursor: pointer;
  margin-left: $margin4;
}

.error {
  margin-top: $margin4;
}
