@import '../../vars';

.alert {
  padding: 4px;
  width: 100%;
  display: flex;

  svg {
    margin-right: 12px;
  }
}

.info {
  background: $alert-info-bg-color;

  svg {
    color: $alert-info-icon-color;
  }
}

.success {
  background: $alert-success-bg-color;
}

.warn {
  background: $alert-warn-bg-color;
}

.danger {
  background: $alert-danger-bg-color;
}