.tag {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  p {
    white-space: nowrap;
  }
}
