@import '../../vars';

.alertPopUPTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $padding5 $padding16;
  border-bottom: 1px solid $border-color;
}
.alertPopUPContent {
  margin-top: $margin20;
  padding: $padding24 $padding20 $padding24 $padding16 !important;
}

.alertPopUPActions {
  padding: $padding16 !important;
}
/******************** Warning popup css : START **************************/
.WarningPopupMain{
  .deleteIconDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $margin20;
    padding: $padding0 $padding100;
    .warningTitle{
      color: $btnGrayTextColor !important;
      font-weight: $fontWeight500 !important;
      margin-top: $margin14 !important;
    }
  }
  .warningPopupContent {
    margin-top: $margin20;
    padding: $padding0 $padding90 !important; 
    p{
      color: $warningPopupContentColor;
    }   
  }
  .warningPopupActions{
    padding: $padding16 !important;
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-bottom: $margin6;
  }
}
@media screen and (max-width: $mobile) {
  .WarningPopupMain {
    .deleteIconDiv{
      padding: $padding0 !important;
    }
    .warningPopupContent{
      padding: $padding10 !important;
    }
  }
}
/*************************** Warning popup css : END ************************/
