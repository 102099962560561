@import '../../vars';

.textarea {
  font-family: $fontFamilySourceSansPro;
  font-size: $fontSize16;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: $margin8;
  background: $input-light-bg-color;
  color: $input-light-font-color;
  border: 1px solid $input-border-color;
  resize: none;
}

.label {
  display: block;
}

.sublabel {
  margin-top: 0px !important;
}

.required {
  color: $input-required-color;
  margin-left: $margin4;
}

.error {
  margin-top: $margin4;
}
.textarea:disabled {
  background-color: $input-border-color;
}
