@import '../../vars';

.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid $divider-color;
  margin-bottom: auto;

  tr {
    th,
    td {
      text-align: left;
      padding-left: 16px;
    }
  }
}

.tableHeader {
  background: $table-header-bg;

  > tr {
    height: 56px;
  }
}

.tableRow {
  height: 64px;
  border-bottom: 1px solid $divider-color;

  &:hover {
    cursor: pointer;
    background: $table-row-hover-bg;
  }
}

.paginationWrapper {
  margin-top: $margin16;
  display: flex;
  flex-direction: row-reverse;
}
