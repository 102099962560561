@import '../../vars';
.revshareCardAmount {
  margin-top: 10;
  font-weight: bolder;
  font-size: 24px;
}
.earningCard {
  background-color: white;
  width: 28%;
  padding: 20px;
  margin-top: 15px;
  border-radius: 8px;
  text-align: center;
}
.potentialEligiblity {
  position: absolute;
  right: 106%;
  top: 2px;
  background-color: #dd0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.potentialEligiblityWrapper {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  margin-left: 83%;
}
.revShareHeading {
  font-size: 15;
  font-family: Source Serif;
  font-style: italic;
}
.revShareSubHeading {
  font-size: 22;
  font-family: Source Serif;
  font-style: italic;
}
.tableContainer {
  margin-bottom: 16px;
}
.accordionSummary {
  background-color: #f4f4f4 !important;
}
.lockIcon {
  width: 20px;
  height: 20px;
}
.tierNumUnlocked {
  font-weight: bolder;
  color: black;
}
.tierNumLocked {
  font-weight: bolder;
  color: black;
}
.title {
  width: 100%;
  align-items: center !important;
  .unlockedTitle {
    color: #4131fe;
    font-size: 16px !important;
  }
  .lockedTitle {
    color: #dd0000;
    font-size: 16px !important;
  }
}
.footerCell {
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
}
.lockedFooterCell {
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  color: #dd0000;
}
.tierNum {
  font-size: 16px !important;
}
@media screen and (max-width: $mobile) {
  .earningCard {
    width: 50%;
  }
  .lockIcon {
    width: 10px;
    height: 10px;
  }
  .title {
    width: 100%;
    align-items: center !important;
    .unlockedTitle {
      color: #4131fe;
      font-size: 12px !important;
      line-height: 15px;
    }
    .lockedTitle {
      color: #dd0000;
      font-size: 12px !important;
      line-height: 15px;
    }
  }
  .tierNum {
    font-size: 12px !important;
    line-height: 15px;
    text-wrap: nowrap;
  }
  .potentialEligiblityWrapper {
    font-size: 12px;
    text-wrap: nowrap;
    margin-left: 70%;
  }
  .potentialEligiblity {
    width: 10px;
    height: 10px;
  }
  .header {
    font-size: 10px !important;
    line-height: 13px !important;
    padding: 0;
  }
}
