@import '../../vars';

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.item {
  padding: 24px;
  border: 1px solid $divider-color;
}

.docs {
  grid-row: 1 / 3;
}

@media screen and (max-width: $mobile) {
  .items {
    display: flex;
    flex-direction: column;
  }

  .item {
    padding: 12px;
  }
}
