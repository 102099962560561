@import '../../vars';

.stepperLabel > span:nth-child(2) > span {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize20;
  line-height: 25px;
}
.stepperLabel > span:nth-child(2) > span.Mui-active {
  color: $black !important;
}
.stepperLabel > span:nth-child(2) > span.Mui-completed {
  color: $black !important;
}

.stepperLabel {
  .customStepIcon {
    font-size: 2rem;
    color: $stepperIconDefaultColor;
  }
  .customActiveStepIcon {
    color: $stepperIconColor !important;
  }
  .customCompletedStepIcon {
    color: $stepperIconColor !important;
  }
}
.dottedLineStepper .MuiStep-alternativeLabel .MuiStepConnector-line {
  border: 2px dashed $stepperBorderColor;
}
.dottedLineStepper .MuiStepConnector-root {
  top: 15px !important;
  left: calc(-50% + 28px) !important;
  right: calc(50% + 28px) !important;
}
.stepperMain {
  .stepperBox {
    margin-bottom: 40px;
  }
}

// @media screen and (min-width:"1150px") and (max-width:"1560px"){
/* CSS for 125% zoom level */
@media screen and (min-resolution: 120dpi) and (max-resolution: 130dpi) {
  .stepperLabel > span:nth-child(2) > span {
    font-size: $fontSize17;
  }
  .stepperLabel {
    .customStepIcon {
      font-size: 1.7rem;
    }
  }
  .stepperMain {
    .stepperBox {
      margin-bottom: $margin22;
    }
  }
  .dottedLineStepper .MuiStepConnector-root {
    top: 12px !important;
    left: calc(-50% + 22px) !important;
    right: calc(50% + 22px) !important;
  }
}

/* CSS for 150% zoom level */
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  .stepperLabel > span:nth-child(2) > span {
    font-size: $fontSize15;
  }
  .stepperLabel {
    .customStepIcon {
      font-size: 1.5rem;
    }
  }
  .stepperMain {
    .stepperBox {
      margin-bottom: $margin20;
    }
  }
  .dottedLineStepper .MuiStepConnector-root {
    top: 10px !important;
    left: calc(-50% + 20px) !important;
    right: calc(50% + 20px) !important;
  }
}

// @media screen and (min-width: "1081px") and (max-width: "1149px"){
/* CSS rules for 175% zoom level */
@media screen and (min-resolution: 168dpi) and (max-resolution: 186dpi) {
  .stepperLabel > span:nth-child(2) > span {
    font-size: $fontSize12;
  }
  .stepperLabel {
    .customStepIcon {
      font-size: 1.3rem;
    }
  }
  .stepperMain {
    .stepperBox {
      margin-bottom: -33px;
    }
  }
  .dottedLineStepper .MuiStepConnector-root {
    top: 9px !important;
    left: calc(-50% + 16px) !important;
    right: calc(50% + 16px) !important;
  }
}
