$mobile: 992px;
$fontFamilySourceSansPro: Source Sans Pro;
$width100: 100%;
$width11: 11px;
$width14: 14px;
$width16: 16px;
$width26: 26px;
$width30: 30px;
$width35: 35px;
$width80: 80px;
$width90: 90px;
$width100PX: 100px;
$width110: 110px;
$width141: 140px;

/* ================ Margin START ============= */
$margin0: 0px;
$margin1: 1px;
$margin2: 2px;
$margin4: 4px;
$margin5: 5px;
$margin6: 6px;
$margin7: 7px;
$margin8: 8px;
$margin10: 10px;
$margin12: 12px;
$margin14: 14px;
$margin16: 16px;
$margin20: 20px;
$margin22: 22px;
$margin24: 24px;
$margin26: 26px;
$margin30: 30px;
$margin35: 35px;
$margin40: 40px;
$margin45: 45px;
$margin50: 50px;
/* ================ Margin END ============= */

/* ================ Padding START ============= */
$padding0: 0px;
$padding4: 4px;
$padding5: 5px;
$padding10: 10px;
$padding16: 16px;
$padding20: 20px;
$padding24: 24px;
$padding25: 25px;
$padding28: 28px;
$padding30: 30px;
$padding35: 35px;
$padding40: 40px;
$padding90: 90px;
$padding100: 100px;
/* ================ Padding END ============= */

/* ================ Font weight START ============= */
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
/* ================ Font weight END ============= */

/* ================ Font size START ============= */
$fontSize7: 7px;
$fontSize8: 8px;
$fontSize9: 9px;
$fontSize10: 10px;
$fontSize11: 11px;
$fontSize12: 12px;
$fontSize13: 13px;
$fontSize14: 14px;
$fontSize15: 15px;
$fontSize16: 16px;
$fontSize17: 17px;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize21: 21px;
$fontSize22: 22px;
$fontSize24: 24px;
$fontSize25: 25px;
$fontSize26: 26px;
$fontSize28: 28px;
$fontSize30: 30px;
$fontSize36: 36px;
$fontSize40: 40px;
/* ================ Font size END ============= */

$font-color-primary: #000000;
$font-color-secondary: #737373;
$font-color-dark: #0d0d0d;
$font-color-white: #ffffff;
$font-color-link: #0f62fe;
$font-color-link-visited: #8a3ffc;
$font-color-error: #e90b0b;
$font-color-success: #4bb543;
$font-color-documents: #2c2c2c;

$nav-bg-color: #0d0d0d;
$nav-font-color: #bfbfbf;
$nav-hover-color: #ffffff;
$nav-active-bg-color: #333333;
$nav-divider-color: #262626;

$dashboard-bg-color: #fafafa;

$black: #000000;
$divider-color: #d9d9d9;
$border-color: #d9d9d9;
$table-border-color: #f5f5f5;
$table-cell-color: #525252;
$menuButton-border-color: #d2d2d2;
$progress-bar: #d9d9d9;

$loading-inner-color: #b4c3ca;
$loading-outer-color: #0d0d0d;
$loading-inner-color-secondary: #808080;
$loading-outer-color-secondary: #ffffff;

$btn-primary-bg-color: #0d0d0d;
$btn-primary-font-color: #ffffff;
$btn-secondary-bg-color: #bfbfbf;
$btn-secondary-font-color: #0d0d0d;
$btn-tertiary-bg-color: #ffffff;
$btn-tertiary-font-color: #0d0d0d;
$btn-plain-bg-color: #ffffff;
$btn-plain-font-color: #0d0d0d;

$radio-color: #0d0d0d;

$signin-bg-color: #0d0d0d;

$input-dark-bg-color: #404040;
$input-dark-font-color: #ffffff;
$input-light-bg-color: #ffffff;
$input-light-font-color: #0d0d0d;
$input-border-color: #d9d9d9;
$input-required-color: #e90b0b;

$alert-info-bg-color: #b0cbff;
$alert-info-icon-color: #0f62fe;
$alert-info-font: #0d0d0d;

$alert-success-bg-color: #eaf6ed;

$alert-warn-bg-color: #fcf4d6;
$alert-warn-icon-color: #f1c21b;

$alert-danger-bg-color: #fff1f1;
$alert-danger-icon-color: #da1e28;

$checkIcon: #4bb543;
$closeIcon: #e90b0b;

$dot-color: #d9d9d9;

$table-header-bg: #d9d9d9;
$table-row-hover-bg: #fafafa;

$nav-notification-bg: #262626;
$nav-notification-color: #f1c21b;

$comment-bg: #f2f2f2;
$comment-dash: #d9d9d9;

$chat-channel-hover: #404040;
$chat-bubble: #f2f2f2;
$chat-online-icon: #4bb543;

$select-primary-color: #0d0d0d;
$select-hover-color: #d9d9d9;

$stepperIconColor: #010101;
$stepperIconDefaultColor: #ababab;
$stepperBorderColor: #898989;

$requiredIcon: #ff0000;

$btnGrayBgColor: #efefef;
$btnGrayTextColor: #2e2e2e;
$warningPopupContentColor: #b1b1b1;

$commentDividerColor: #d2d2d2;
$commnetHeaderColor: #5e5e5e;
$commentDateColor: #989898;

$cardBgColor: #f6f6f6;
$sidebarBgColor: #343434;
$sidebarActive: rgba(217, 217, 217, 0.11);
$primaryLight: #d3d3d3;
$disabled-feild-color: #d9d9d9;

:export {
  loading-inner-color: $loading-inner-color;
  loading-outer-color: $loading-outer-color;
  loading-inner-color-secondary: $loading-inner-color-secondary;
  loading-outer-color-secondary: $loading-outer-color-secondary;
  nav-divider-color: $nav-divider-color;
  font-color-dark: $font-color-dark;
  font-color-secondary: $font-color-secondary;
  font-color-white: $font-color-white;
  font-color-error: $font-color-error;
  dot-color: $dot-color;
  doc-font-color: $font-color-documents;
  nav-notification-color: $nav-notification-color;
  nav-notification-bg: $nav-notification-bg;
  alert-warn-icon-color: $alert-warn-icon-color;
  alert-danger-icon-color: $alert-danger-icon-color;
  select-primary-color: $select-primary-color;
  select-hover-color: $select-hover-color;
  font-color-primary: $font-color-primary;
  table-border-color: $table-border-color;
  btn-secondary-bg-color: $btn-secondary-bg-color;
  menuButton-border-color: $menuButton-border-color;
  table-cell-color: $table-cell-color;
  progress-bg-color: $progress-bar;
  stepperBorderColor: $stepperBorderColor;
  requiredIcon: $requiredIcon;
  fontFamilySourceSansPro: $fontFamilySourceSansPro;
  fontSize20: $fontSize20;
  fontSize22: $fontSize22;
  margin12: $margin12;
  margin22: $margin22;
  margin35: $margin35;
  width141: $width141;
  cardBgColor: $cardBgColor;
  disabled-feild-color: $disabled-feild-color;
}
