@import '../../vars';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: $margin0;
}

.primary {
  color: $font-color-primary;
}

.secondary {
  color: $font-color-secondary;
}

.white {
  color: $font-color-white;
}

.error {
  color: $font-color-error;
}

.success {
  color: $font-color-success;
}

.heading_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight700;
  font-size: $fontSize40;
  line-height: 56px;
}

.heading_2 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight700;
  font-size: 36px;
  line-height: 44px;
}

.heading_3 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight700;
  font-size: 32px;
  line-height: 40px;
}

.heading_4 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: 28px;
  line-height: 36px;
}

.heading_5 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize24;
  line-height: 30px;
}

.heading_6 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize20;
  line-height: 28px;
}

.subheading_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize18;
  line-height: 26px;
}

.subheading_2 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize16;
  line-height: 24px;
}

.subheading_3 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize14;
  line-height: 20px;
}

.body_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize18;
  line-height: 26px;
}

.body_2 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize16;
  line-height: 24px;
}

.body_3 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize14;
  line-height: 20px;
}

.body_4 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize12;
  line-height: 16px;
}

.body_5 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize20;
  line-height: 25px;
}

.caption_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize12;
  line-height: 16px;
}

.overline_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize12;
  line-height: 16px;
  text-transform: uppercase;
}

.label_1 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight400;
  font-size: $fontSize16;
  line-height: 24px;
}

.label_2 {
  font-family: $fontFamilySourceSansPro;
  font-weight: $fontWeight600;
  font-size: $fontSize14;
  line-height: 20px;
}

.link_1 {
  font-family: $fontFamilySourceSansPro;
  font-style: normal;
  font-weight: $fontWeight400;
  font-size: $fontSize16;
  line-height: 24px;
  color: $font-color-link;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:visited {
    color: $font-color-link-visited;
  }
}
