@import '../../vars';

.homeWrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

section::-webkit-scrollbar {
  display: none;
}

.announcements {
  overflow-y: auto;
}

.revshareTime {
  font-weight: 200;
  font-size: 16px !important;
  color: $stepperIconDefaultColor;
}
.cgiTime {
  font-weight: 200;
  font-size: 16px !important;
  color: $stepperIconDefaultColor;
}

@media screen and (max-width: $mobile) {
  .cardBtnText {
    display: none;
  }
  .home {
    grid-template-columns: 1fr;
  }

  .announcements,
  .homeWrapper {
    overflow-y: visible;
  }

  .chevron {
    display: none;
  }

  .dealCards {
    flex-direction: column;
  }

  .dealCard {
    width: 100%;
  }
}

.announcementPopUpTitle {
  color: $commnetHeaderColor;
  font-weight: 400;
}
