@import '../../vars';

.delete {
  color: $alert-danger-icon-color;
}

.addButton {
  width: $width141;
  margin-top: $margin10;
  margin-right: $margin0;
  display: flex;
}

.fileIcon {
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.download {
  position: absolute !important;
  right: -16px;
  top: -25px;
}

.deleteCheckbox {
  position: absolute !important;
  right: -16px;
  top: -25px;
}

.fileIcon {
  .download {
    display: none;
    &:hover {
      background: none;
    }
  }
  &:hover {
    .download {
      display: block;
    }
  }
}

.tabs {
  align-self: left;
  margin-bottom: 10px;
  height: 73vh;
}

.headerContent {
  align-items: center !important;
  margin-bottom: 40px;
  justify-content: space-between !important;
}

.content {
  background-color: #f9f9f9;
  height: 70vh;
  overflow-y: auto;
  .contentList {
    display: flex;
    flex-wrap: wrap;
  }

  .emptyFolder {
    align-items: center !important;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.createFolderPopup {
  height: 200px;
  width: 450px;
}

@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  span {
    font-size: 14px;
  }
}

@media screen and (max-width: $mobile) {
  .download {
    right: -48px;
    top: -30px;
  }

  .deleteCheckbox {
    right: -48px;
    top: -30px;
  }

  .headerContent {
    margin-bottom: 10px;
  }

  .createFolderPopup {
    height: 200px;
    width: 250px;
  }
}
