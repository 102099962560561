@import '../../vars';

@mixin nav-item {
  display: flex;
  color: $font-color-white;
  padding: 18px 0px 18px 58px;
  span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

#desktop-nav {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center !important;
  overflow-y: auto;
}

#desktop-nav,
#mobile-nav {
  background: linear-gradient(180deg, $sidebarBgColor 0%, $font-color-primary 100%);

  nav {
    display: flex;
    flex: 1;

    #menu-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;

      ul {
        margin: 0px;
        padding: 0px;

        svg {
          margin-right: 12px;
        }

        .nav-item {
          a,
          .nav-item-logout {
            @include nav-item;
            text-decoration: none;
          }

          &:hover {
            cursor: pointer;
            color: $nav-font-color;
            a,
            .nav-item-logout {
              color: $nav-font-color;
            }
          }
        }

        .active {
          background: $sidebarActive;
          border-right: 2px solid $primaryLight;
        }
      }
    }
  }
}

#mobile-nav {
  display: none;
  color: $font-color-white;
  padding: 8px 24px;

  #mobile-logo {
    height: 32px;
    display: flex;
    align-items: flex-start;
    svg {
      height: 100%;
    }
  }

  .nav-item {
    border-top: 1px solid $sidebarActive;
    a,
    .nav-item-logout {
      margin: 0px !important;
      padding: 10px 16px !important;
    }
  }
}

.mobile-nav-drawer {
  display: none;
  background: white;
  position: fixed;
  top: 48px;
  right: 0;
  width: 100%;
  z-index: 100;
  background: linear-gradient(180deg, $sidebarBgColor 0%, $font-color-primary 100%);
}

.mobile-nav-drawer-open {
  display: flex;
}

@media screen and (max-width: $mobile) {
  #desktop-nav {
    display: none;
  }

  #mobile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #menuItem {
    display: none;
  }
}
