@import '../../vars';

.calculator__container {
  background: black;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: 'Source Serif Pro';
  overflow-y: auto;
  height: 100vh;
  svg {
    height: auto;
  }
  img {
    width: 15%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .backButton {
    color: white;
    width: 90%;
    align-items: center !important;
    padding-left: auto;
  }
}
.calculator__box-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: scaleHeight(20) vh;
  display: flex;
  justify-content: center;
  border: 2px solid #fff;
  padding: scaleWidth(20) vh;
  &.no-border {
    border: none;
    flex-direction: column-reverse;
  }
}
.calculator__left-box {
  &._container {
    width: 58%;
    background: #000 0 0 no-repeat padding-box;
    width: 100%;
  }
}
.calculator__divider {
  height: 96%;
  width: 0;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-left: 5px !important;
  margin-right: scaleWidth(5) vw;
  margin-top: 10px !important;
  width: 0.75%;

  &._longer {
    height: scaleHeight(1100) vh;
  }
}
.calculator__right-box {
  &._container {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: #000 0 0 no-repeat padding-box;
    width: 100%;
  }
  hr {
    width: 90%;
    border-top: 1px solid #fff;
    margin-top: 5px;
  }
}
.calculator__title {
  font-family: 'Muller', sans-serif;
  &._container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: scaleHeight(20) vh;
    hr {
      width: 80%;
      border-top: 3px solid #fff;
      margin-top: 5px;
    }
  }
  &._title-name {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 100;
    color: #fff;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
  &._disclaimer {
    font-family: 'Karla Regular', sans-serif;
    font-size: 1.2rem;
    text-align: center;
    color: #000;
    margin-bottom: scaleHeight(20) vh;
    span {
      color: #f00;
    }
  }
}
.calculator__section {
  &._container {
    margin-left: auto;
    margin-right: 10px;
    padding-top: 20px !important;
  }
  &._title {
    font-family: 'Muller Bold', sans-serif;
    text-transform: uppercase;
    margin-bottom: scaleHeight(40) vh;
    &.sub-title {
      font-size: 1.25rem;
      color: #fff;
      font-weight: 100;
      text-align: center;
      font-family: 'Muller', sans-serif;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: scaleHeight(15) vh;
      font-size: 1.4rem;
    }
    hr {
      margin-top: 5px;
      border-top: 1px solid #fff;
      width: 90%;
    }
    span {
      font-family: 'Karla Bold', sans-serif;
    }
  }
  hr {
    width: 98%;
    border-top: 2px solid #000;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: scaleHeight(10) vh;
    &.short {
      width: 50%;
      margin-left: 0;
      margin-right: 0;
    }
    &.light {
      width: 80%;
      margin-left: 0;
      margin-right: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
.calculator__field-row {
  font-family: 'Karla Regular', sans-serif;
  letter-spacing: 0.8px;
  &._container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px !important;
    &.spaced {
      justify-content: space-evenly;
    }
  }
  input {
    background-color: #fff;
    color: #000;
    font-size: 1.4rem;
    font-weight: 400;
    border: none;
    height: scaleHeight(50) vh;
    text-align: center;
    margin-left: scaleWidth(20) vw;
    &.wide-input {
      width: 25%;
      margin-left: 2.5%;
      margin-right: 2.5%;
      padding: 2px;
    }
    &.lg-wide-input {
      width: 35%;
      margin-left: 2.5%;
      margin-right: 2.5%;
      padding: 2px;
    }
    &.xl-wide-input {
      width: 70%;
      margin-left: 2.5%;
      margin-right: 5%;
      padding: 2px;
    }
    &.transparent-background {
      background-color: #000;
      color: #fff;
      font-weight: 600;
    }
    &.input-touchable {
      background-color: #fff;
      border: 1px solid #000;
    }
    &::placeholder {
      font-weight: 100;
      font-size: 1.3rem;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.calculator__field {
  font-family: 'Muller', sans-serif;
  &._label {
    font-family: 'Muller Bold', sans-serif;
    width: 30%;
    text-align: center;
    &.field-section-label-font {
      font-size: 1.25rem;
      font-weight: 100;
      color: #fff;
    }
    &.sm-table-col {
      width: 20%;
    }
    &.wide-table-col {
      width: 30%;
    }
    &.wide-table-col-mobile {
      width: 30%;
      width: 8%;
    }
    &.lg-wide-table-col {
      width: 40%;
    }
    &.form {
      width: 45%;
    }
    &.long-label {
      width: 70%;
    }
    &.full {
      width: 100%;
    }
    &.sm-gap {
      width: fit-content;
      padding-right: scaleWidth(30) vw;
    }
    &.no-gap {
      width: fit-content;
      padding-right: scaleWidth(10) vw;
    }
    &.inner-label {
      font-family: 'Muller', sans-serif;
      width: fit-content;
      padding-right: 0;
      margin-left: scaleWidth(10) vw;
    }
    &.sub-title {
      width: 100%;
      font-weight: 700;
      text-align: center;
      font-family: 'Muller', sans-serif;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
    }
    span {
      font-family: 'Karla Bold', sans-serif;
    }
    hr {
      margin-top: 5px;
      border-top: 1px solid #fff;
      width: 70%;
    }
  }
  &._caption {
    font-family: 'Karla Regular', sans-serif;
    font-size: 1.1rem;
  }
  &._caption-italic {
    font-family: 'Karla Regular', sans-serif;
    font-size: 1.2rem;
    text-align: left;
    color: #808080;
    a {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.3rem;
      text-decoration: none;
    }
  }
  &._add {
    margin-top: scaleHeight(10) vh;
    cursor: pointer;
    svg {
      width: scaleWidth(50) vw;
      height: scaleWidth(50) vw;
    }
  }
  &._remove {
    margin-top: scaleHeight(10) vh;
    margin-left: scaleWidth(14) vw;
    cursor: pointer;
    svg {
      width: scaleWidth(20) vw;
      height: scaleWidth(20) vw;
    }
  }
  &._box {
    width: scaleWidth(45) vw;
    margin-top: -5px;
    margin-right: scaleWidth(15) vw;
  }
  &._upload-icon {
    margin-right: scaleWidth(15) vw;
    cursor: pointer;
    input {
      display: none;
    }
    svg {
      width: scaleWidth(50) vw;
      height: scaleWidth(50) vw;
    }
  }
  &._file-name {
    font-family: 'Karla', sans-serif;
    color: rgba(0, 0, 0, 0.3);
    font-size: 1.1rem;
    letter-spacing: 0;
    width: 35%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
  &._textarea {
    font-weight: 600;
    letter-spacing: 0.8px;
    font-size: 1.2rem;
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: scaleHeight(20) vh;
    padding-left: scaleWidth(10) vw;
    &::placeholder {
      text-align: center;
    }
  }
  &._delete {
    margin-left: scaleWidth(10) vw;
    cursor: pointer;
    svg {
      width: scaleWidth(20) vw;
      height: scaleWidth(20) vw;
    }
  }
  &._filler {
    width: 28%;
  }
}
.calculator__terms {
  &._container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &._terms {
    font-family: 'Muller Bold', sans-serif;
    margin-top: scaleHeight(10) vh;
  }
}
.calculator__control {
  font-family: 'Karla Regular', sans-serif;
  &._container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  &._item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    width: 28%;
    height: scaleHeight(55) vh;
    svg {
      width: scaleWidth(50) vw;
      height: scaleWidth(50) vw;
    }
  }
  &._text {
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 5% ;
    margin-left: scaleWidth(10) vw;
    padding-top: scaleHeight(5) vh;
    &:hover {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
.calculator__social {
  &._container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: scaleHeight(20) vh;
    a {
      font-family: 'Karla Bold', sans-serif;
      text-decoration: none;
      color: #fff;
      font-size: 1.3rem;
      font-size: 1.5rem;
    }
  }
  &._icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      svg {
        width: scaleWidth(43) vw;
        height: scaleWidth(43) vw;
        margin-right: scaleWidth(20) vw;
        width: scaleWidth(140) vw;
        height: scaleWidth(140) vw;
        &.facebook {
          width: scaleWidth(32) vw;
          height: scaleWidth(32) vw;
          margin-right: scaleWidth(15) vw;
          width: scaleWidth(120) vw;
          height: scaleWidth(120) vw;
        }
      }
    }
    span {
      font-size: 1.3rem;
      color: #fff;
      font-size: 1.5rem;
    }
  }
}
@media screen and (max-width: $mobile) {
  .calculator__container {
    flex-direction: column;
    img {
      width: 35%;
    }
  }
  .calculator__box-container {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .calculator__divider {
    width: 96%;
    height: 0%;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-left: 0;
    border-right: 0;
    padding-top: 2px;

    &._longer {
      height: scaleHeight(8) vh;
    }
  }
  .calculator__field {
    &._label {
      &.field-section-label-font {
        font-size: 14px;
      }
    }
    &._caption {
      font-size: 10px;
    }
  }
  .calculator__field-row {
    input {
      font-size: 16px;
      &.wide-input {
        padding: 0%;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      &.lg-wide-input {
        padding: 0%;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      &.xl-wide-input {
        padding: 0%;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  .calculator__section {
    &._title {
      &.sub-title {
        font-size: 15px;
      }
    }
  }
  .calculator__control {
    &._text {
      font-size: 13px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
