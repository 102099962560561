.conversationWrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.conversation {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
