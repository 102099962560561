@import '../../vars';

.pagination {
    display: flex;
    gap: 0;
    flex-direction: row;
    justify-content: center;
}

.paginationItem {
    background: $nav-hover-color;
    color: $nav-bg-color;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-content: center;

    p {
        margin-top: auto;
        margin-bottom: auto;
    }
    svg {
        height: 100%;
        width: 100%;
    }
    &.disabled {
        opacity: 0.5; // Adjust the styling for disabled icons
        cursor: default;
      }
}

.paginationDots{
    user-select: none;
    cursor: default;
    height: 32px;
    width: 24px;
    display: flex;
    align-items: center;
    svg {
        height: 60%;
    }
}

.selected {
    background: $nav-bg-color;

    p {
        color: $nav-hover-color;
    }
}