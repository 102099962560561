@import '../../../vars';

.addChannel {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;
}

.button {
  margin-top: $margin24;
  margin-bottom: $margin24;
}

.users {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
}

@media screen and (max-width: $mobile) {
  .users {
    grid-template-columns: 1fr;
  }
}
