@import '../../vars';

$spaceBwLines: 8px;

.divider {
  margin: 24px 0px;
  border-top: 1px solid $divider-color;
  height: 1px;
}

.vertical {
  height: calc(100% - 4px);
  margin: 2px 16px;
  border-right: 1px solid $divider-color;
  width: 1px;
}

.text {
  display: flex;
  margin: 24px 0px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
}

.text::before {
  margin-right: $spaceBwLines;
}

.text::after {
  margin-left: $spaceBwLines;
}

.text::before,
.text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid $divider-color;
  transform: translateY(50%);
  content: '';
}
