@import '../../vars';

.filter {
  display: inline-flex;
  flex-direction: column;
  padding: 8px 0;
  border: 1px solid $divider-color;
  margin-bottom: auto;

  .option {
    cursor: pointer;
    height: 40px;
    width: 160px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 16px;
  }

  .selected {
    cursor: default;
  }
}

.filterTabs {
  display: inline-flex;
  margin-right: auto;

  .option {
    cursor: pointer;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid $divider-color;
  }

  .selected {
    cursor: default;
    border-bottom: 2px solid $black;
  }
}

@media screen and (max-width: $mobile) {
  .filter {
    // display: none;
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
  }
}
