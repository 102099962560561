
@import '../../vars';

.announcementActions {
  display: flex !important;
  justify-content: flex-end !important;
}

.compose {
  width: 100% !important;
  font-size: 18;
  font-weight: 600;
}

.attachmentPopup {
  height: 500px;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  .attachmentListContainer {
    display: flex;
    flex-wrap: wrap;
    .attachmentList {
      width: 400px;
      :hover {
        background-color: aliceblue;
      }
    }
  }
}

.expand {
  position: absolute !important;
  right: 4px;
  top: 0px;
}

.announcementList {
  border: 1px solid #ebebeb;
  .list {
    margin-top: 15px;
    overflow-y: auto;
    height: calc(68vh - 0px);
    background: #f9f9f9 !important;
    .created {
      font-size: 10px;
      color: $stepperBorderColor;
      line-height: 1 !important;
    }
    .preview {
      color: $stepperBorderColor;
      margin-top: 3px;
    }
    .row {
      padding: 4px 10px 4px 10px;
      margin-left: 5px;
      position: relative;
      .expand {
        display: none;
        &:hover {
          background: none;
        }
      }
      &:hover {
        .expand {
          display: block;
        }
      }
    }
    .readBox {
      cursor: pointer;
      :hover {
        background-color: #f7fcff;
      }
    }
    .unReadBox {
      cursor: pointer;
      background-color: #e8f1fc;
      :hover {
        background-color: #c1d7e5;
      }
    }
    .noAnnouncements {
      color: $table-cell-color;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.announcementPreview {
  border: 1px solid #ebebeb;
  background: #f9f9f9 !important;
  .title {
    font-size: 18px;
    color: #36454f;
  }
  .preview {
    color: $stepperBorderColor;
    margin-top: 3px;
    width: 80%;
  }
  .created {
    color: $stepperBorderColor;
    margin-top: 3px;
  }
  .previewContent {
    overflow-y: auto;
    height: calc(60vh - 0px);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .attachment {
      width: 50% !important;
      padding-bottom: 16px;
      padding-right: 16px;
    }
    .attachmentView {
      width: 33% !important;
      padding-bottom: 16px;
      padding-right: 16px;
    }
    .row {
      border: 1px solid #cacaca;
      padding: 8px;
      justify-content: space-between !important;
    }
  }
}

.announcementPreviewBox {
  margin-top: 11px;
  .title {
    font-size: 7px;
    color: #36454f;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    .attachment {
      width: 33.3% !important;
      padding-bottom: 3px;
      padding-right: 6px;
    }
    .row {
      border: 1px solid #cacaca;
      padding: 1px;
      justify-content: space-between !important;
      font-size: 14px;
    }
  }
}

.schedule {
  height: 350px;
  width: 400px;
}

.subHeading {
  color: $stepperBorderColor;
}

.attachmentPreview {
  .docs {
    width: 100%;
    height: 100vh;
  }
  .image {
    width: auto;
    height: 100vh;
  }
  .videoCenter{
    display: flex;
    align-items: center;
    justify-content: center;
    .video {
      width: auto;
      height: 80vh;
    }
  }
}

.inValidFileType {
  display: flex;
  justify-content: center;
  color: $table-cell-color;
  padding-top: 100px;
}

@media screen and (max-width: $mobile) {
  .schedule {
    height: 400px;
    width: 300px;
  }

  .announcementFormFooter {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 8px !important;
  }

  .attachmentPopup {
    width: 300px;
  }

  .announcementPreview {
    .preview {
      width: 60%;
    }

    .container {
      .attachmentView {
        width: 100% !important;
      }
    }
  }

  .viewAnouncement {
    margin-top: -50px;
  }

  .attachmentPreview {
    min-width: 280px;
    min-height: 300px;
    .docs {
      width: 400px;
      height: 500px;
    }
    .image {
      height: 280px;
    }
    .video {
      height: 230px;
      width: 320px;
    }
  }
}


.imageContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


.zoomControls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;

  button {
    background-color: #fff;
    border: 2px solid #ccc;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
    }

    &:focus {
      outline: 2px solid #007BFF;
      outline-offset: 2px;
    }
  }
}

.image {
  transition: transform 0.2s ease-in-out;
}
	