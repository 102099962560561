@import '../../vars';

.yearPickerMain {
  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: $font-color-documents;
    font-size: $fontSize30;
    margin-top: $margin2;
  }

  .react-datepicker-wrapper {
    width: $width100;
  }

  .react-datepicker__close-icon {
    margin-right: $margin35;
    margin-top: $margin1;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
    background-color: $input-border-color;
  }
  .react-datepicker__close-icon::after {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

// Date Time Picker css
.MuiPickersSlideTransition-root {
  .MuiDateCalendar-root {
    .MuiPickersFadeTransitionGroup-root {
      .MuiDayCalendar-weekContainer {
        .MuiButtonBase-root.Mui-selected {
          background-color: $black !important;
          :hover {
            background-color: $select-hover-color !important;
          }
        }
      }
    }
  }
}

.MuiPickersLayout-contentWrapper {
  .MuiTabs-root,
  .MuiDateTimePickerTabs-root {
    .MuiTab-textColorPrimary.Mui-selected {
      color: $font-color-white !important;
    }
  }
  button.Mui-selected {
    background-color: $black !important;
  }
  .MuiTimeClock-root {
    button.MuiClock-pmButton,
    button.MuiClock-amButton {
      background-color: $black !important;
      color: $font-color-white;
    }
    .MuiClockPointer-root,
    .MuiClock-pin,
    .MuiClock-wrapper span.Mui-selected {
      background-color: $black !important;
    }
    .MuiClockPointer-root .MuiClockPointer-thumb {
      border: 16px solid #3b3b3b !important;
    }
  }
}

.MuiDialogActions-root {
  .MuiButton-textPrimary {
    color: $black;
  }
}
