@import '../../../vars';

.sidebar {
  height: 100%;
  border-left: 1px solid $nav-divider-color;
  width: 240px;
  background: $nav-bg-color;
  padding: 10px 6px;
  overflow-y: auto;
  flex-shrink: 0;

  p {
    color: $nav-font-color;
  }

  .icon {
    color: $nav-font-color;
  }
}

.sidebarOption {
  display: flex;
  align-items: center;
  padding: 4px 10px;

  .icon,
  .onlineIcon {
    margin-right: $margin8;
    height: 16px !important;
    width: 16px !important;
  }

  &:hover {
    cursor: pointer;
    p,
    .icon,
    .onlineIcon {
      color: $chat-channel-hover;
    }
  }

  .onlineIcon {
    color: $chat-online-icon;
  }
}

.mobileSidebar {
  display: none;
  background: $nav-bg-color;

  p,
  label,
  .icon {
    color: $nav-font-color;
  }

  .icon {
    margin-right: $margin6;
    height: 28px !important;
    width: 28px !important;
  }

  .mobileSidebarItem {
    padding: 8px 0px;
    flex: 1;
    flex-direction: column;
    border-left: 1px solid $nav-divider-color;
    border-right: 1px solid $nav-divider-color;
    &:hover {
      background: $chat-channel-hover;
    }
  }
}

@media screen and (max-width: $mobile) {
  .sidebar {
    display: none;
  }
  .mobileSidebar {
    display: flex;
    justify-content: space-between;
  }
}
