@import '../../vars';

.chat {
  display: flex;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.outlet {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $mobile) {
  .chat {
    flex-direction: column-reverse;
  }
}
