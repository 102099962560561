.avatar {
  border-radius: 50%;
  background: #f2f2f2;
  position: relative;
  flex-shrink: 0;

  p {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.avatarImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
