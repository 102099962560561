@import 'vars';
@import './assets/style/Steppers.scss';
@import './assets/style/SystemResolutionStyle.scss';

html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: calc(100vh - 0px);
  overflow-y: hidden;
  font-family: 'Source Sans Pro';
  color: $font-color-primary;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8c8c8c;
}

* {
  box-sizing: border-box;
}

#root,
#app-layout,
#nav {
  height: 100%;
}

#app-layout {
  display: flex;
  section:nth-of-type(2) {
    flex: 1;
  }

  #app-content {
    width: 100%;
    padding: 40px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}

.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  height: 36px;
  width: 100%;
  border-color: $input-border-color;
}

@media screen and (max-width: $mobile) {
  #app-layout {
    flex-direction: column;
    padding-top: 0px;
    section:nth-of-type(2) {
      flex: none;
    }
    #app-content {
      height: 85%;
      padding-top: 24px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 25px;
    }
  }

  .table-layout-page {
    flex-direction: column;
  }
}

/* CSS for 125% zoom level */
@media screen and (min-resolution: 120dpi) and (max-resolution: 130dpi) {
  #app-layout {
    #app-content {
      padding: $padding28;
    }
  }
}
/* CSS for 150% zoom level */
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  #app-layout {
    #app-content {
      padding: $padding25;
    }
  }
}

/* CSS rules for 175% zoom level */
@media screen and (min-resolution: 168dpi) and (max-resolution: 186dpi) {
  #app-layout {
    #app-content {
      padding: $padding20;
    }
  }
}
