.sendMessage {
  width: 100%;
}

.form {
  display: flex;
  position: relative;
}

.attachmentIcon {
  position: absolute;
  right: 72px;
  top: 6px;
  cursor: pointer;
}
