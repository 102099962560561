@import '../../vars';

.button {
  min-height: 38px;
  padding: 8px 16px;
  border: none;
  font-family: $fontFamilySourceSansPro;
  font-style: normal;
  font-weight: $fontWeight400;
  font-size: $fontSize14;
  line-height: 20px;
  // min-width: 160px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #b5b5b5;
    border-color: #b5b5b5;
  }
  svg {
    height: 16px;
    width: 16px;
  }
  .prefix_icon,
  .suffix_icon {
    height: 16px;
    width: 16px;
  }

  .prefix_icon {
    margin-right: $margin8;
  }

  .suffix_icon {
    margin-left: $margin8;
  }
}

.button_primary {
  background: $btn-primary-bg-color;
  color: $btn-primary-font-color;
  border: 1px solid $btn-primary-bg-color;
}

.button_secondary {
  background: $btn-secondary-bg-color;
  color: $btn-secondary-font-color;
  border: 1px solid $btn-secondary-bg-color;
}

.button_tertiary {
  background: $btn-tertiary-bg-color;
  color: $btn-tertiary-font-color;
  border: 1px solid $btn-tertiary-font-color;
}

.button_plain {
  background: $btn-plain-bg-color;
  color: $btn-plain-font-color;
  border: 1px solid $btn-plain-bg-color;
}
.button_gray {
  background: $btnGrayBgColor;
  color: $btnGrayTextColor;
  border: 1px solid $btnGrayBgColor;
}
.button_lightBlack {
  background: $btnGrayTextColor;
  color: $btn-primary-font-color;
  border: 1px solid $btnGrayTextColor;
}
