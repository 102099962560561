@import 'vars';

.modal {
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: rgba(0, 0, 0, 0.4);

  .body {
    background: #ffffff;
    max-height: 100vh;
    max-width: 520px;

    display: flex;
    flex-direction: column;
    flex: 1;

    .closeIcon {
      cursor: pointer;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 32px;
    }

    .subtitle {
      margin-bottom: $margin8;
    }

    .titleText {
      text-align: center;
    }

    .content {
      padding: 32px;
      overflow-y: auto;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0px 32px 16px 32px;

      button:not(:last-of-type) {
        margin-right: $margin16;
      }
    }

    @media screen and (max-width: $mobile) {
      height: 100vh;
      width: 100%;
      max-width: unset;
      display: flex;
      flex-direction: column;

      .content {
        padding: 0px 32px;
        margin-bottom: auto;
      }
    }
  }
}
